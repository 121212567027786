import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Alert, Box, Divider, FormLabel, IconButton, Typography } from "@mui/material";
import { ReactElement, useEffect } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { emailRegex } from "../../../constants";
import { useMountEffect } from "../../../utils/hooks";
import { Attendee } from "../PollVotingParticipant";
import { IoCloseCircleOutline, IoAdd } from "react-icons/io5";


export type AttendeeInfoFormProps = {
  from: string;
  handleSelectParticipants: (participants: { name: string; email: string }[]) => void,
  setIsAttendeesValid: (isValid: boolean) => void;
  localAttendees: Attendee[];
  setNextStep: (props: {fromNoTimesWorkModal?: boolean, curSelectedCount?: number}) => void;
};

const AttendeeInfoForm = ({ 
  from, handleSelectParticipants, setIsAttendeesValid, localAttendees, setNextStep
}: AttendeeInfoFormProps
): ReactElement => {

  const defaultAttendee: Attendee = { name: '', email: '', emailHash: '', noTimesComment: null };

  const { control, formState, setFocus } = useForm<{attendees: Attendee[]}>({
    defaultValues: { 'attendees': localAttendees.length ? localAttendees : [defaultAttendee] },
    delayError: 1000,
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({control, name: 'attendees'});
  const attendees = useWatch({name: 'attendees', control});

  useMountEffect(() => {
    setFocus(`attendees.${0}.name`);
  });

  useEffect(() => {
    setIsAttendeesValid(formState.isValid);
    if (attendees && formState.isValid) {
      handleSelectParticipants(attendees);
    }
  }, [attendees, formState.isValid, setIsAttendeesValid, handleSelectParticipants]);

  const handleEnter = (key: string) => {
    if (key === 'Enter' && formState.isValid) {
      setNextStep({fromNoTimesWorkModal: false, curSelectedCount: 0});
    }
  };

  return (
    <Box display='flex' flexDirection='column' gap={4}>
      <Typography variant="h1" fontSize={56}>Hello!</Typography>
      <Typography variant="h5" maxWidth={'620px'}>
        <b>{from}</b>&nbsp;
        has invited you to choose your preferred times for this meeting. 
        To get started, enter the details for the attendee of this meeting.
      </Typography>
      <Box maxWidth={'300px'} gap={2} display='flex' flexDirection='column'>
        <form>
          <Box display='flex' flexDirection='column' gap={4}>
            {fields.map((attendee, index) => {
              return (
                <Box key={attendee.id} display='flex' flexDirection='column' gap={2}>
                  {index !== 0 && <Divider sx={{ marginBottom: 1 }} />}
                  <Controller
                    name={`attendees.${index}.name`}
                    defaultValue={`${attendee.name}`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                      <Box maxWidth={'500px'} display='flex' flexDirection='column'>
                        <Box display='flex' justifyContent='space-between'>
                          <FormLabel htmlFor="nameInput">Name*</FormLabel>
                          {index !== 0 && 
                          <IconButton edge='end' aria-label='remove' disableRipple sx={{padding: 0}}
                            onClick={() => remove(index)}
                          >
                            <CabIcon Icon={IoCloseCircleOutline} alt='Remove' />
                          </IconButton>
                          }
                        </Box>
                        <CabTextInput
                          {...field}
                          inputProps={{ id: 'nameInput'}}
                          inputRef={ref}
                          sx={{width: '98%'}}
                          onKeyDown={(e) => handleEnter(e.key)}
                          required
                        />
                      </Box>
                    )}/>
                  <Controller 
                    name={`attendees.${index}.email`}
                    defaultValue={`${attendee.email}`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Email is required"
                      },
                      pattern: {
                        value: emailRegex,
                        message: "Invalid Email"
                      },
                      validate: (email) => {
                        if (attendees.filter(p => p.email === email).length > 1) {
                          return 'A participant with this email already exists';
                        }
                        return true;
                      },
                    }}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <Box maxWidth={'500px'} display='flex' flexDirection='column'>
                        <FormLabel htmlFor="emailInput">Email*</FormLabel>
                        <CabTextInput
                          {...field}
                          type='email'
                          inputProps={{ id: 'emailInput'}}
                          inputRef={ref}
                          sx={{width: '98%'}}
                          onKeyDown={(e) => handleEnter(e.key)}
                          required
                        />
                        {fieldState.error && fieldState.error.message && (
                          <Alert severity="error" sx={{ marginTop: 1 }}>
                            {fieldState.error.message}
                          </Alert>
                        )}
                      </Box>
                    )}/>
                </Box>
              );
            })}
            <Box>
              <CabButton
                buttonType="tertiary"
                color='accent'
                onClick={() => append(defaultAttendee)}
                icon={<CabIcon Icon={IoAdd} alt='Add'/>}
              >
                Add Attendee
              </CabButton>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default AttendeeInfoForm;